// ファイルのアップロード

export default async (url, file) => {
  const res = await fetch(url, {
    method: 'put',
    credentials: 'same-origin',
    body: file,
  })
  return {
    ok: res.ok,
    status: res.status,
  }
}
